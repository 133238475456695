<template>
  <div class="wrapper">
    <AppSidebar />
    <Suspense>
      <template #default>
        <div id="content-page" class="content-page">
          <AppCatogerys :id="id" />
        </div>
      </template>
      <template #fallback>
        <AppLoading />
      </template>
    </Suspense>
  </div>
</template>

<script>
import { Suspense } from "vue";
import AppSidebar from "@/components/layouts/AppSidebar.vue";
import AppCatogerys from "@/components/Pages/AppCatogerys.vue";
import AppLoading from "@/components/Spinners/AppLoading.vue";
export default {
  name: "Catogerys",
  props: {
    id: String,
  },
  components: {
    Suspense,
    AppSidebar,
    AppCatogerys,
    AppLoading,
  },
};
</script>

<style>
</style>