<template>
  <div class="col-lg-12">
    <div class="iq-card" style="margin-bottom: 10vh;">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title" v-for="post in audios.slice(0,1)" :key="post.id">
          <h4 class="card-title">{{ post.name_ar}}</h4>
        </div>
        <!-- <div class="d-flex align-items-center iq-view">
          <b class="mb-0 text-primary"><a href="latest.html">View More <i class="las la-angle-right"></i></a></b>
        </div> -->
      </div>
      <div class="iq-card-body">
        <ul class="list-unstyled row iq-box-hover mb-0">
          <li @click="selectSound(indexo)" :style="indexo == index ? '' : ''" :class="indexo == index ? 'bg-gradient-to-r from-red-500 via-red-600 to-red-700 text-white':''" class="col-6 col-sm-4 col-lg-2 col-xl-2 iq-music-box" v-for="(audio,indexo) in audios" :key="indexo">
            <div class="iq-card">
              <div class="iq-card-body p-0">
                <div class="iq-thumb">
                  <div class="iq-music-overlay"></div>
                    <a>
                      <img :src="audio.img" class="img-border-radius img-fluid w-100" alt="">
                    </a>
                    <div class="overlay-music-icon">
                    <a>
                      <i class="las la-play-circle"></i>
                    </a>
                  </div>
                </div>
                <div class="feature-list text-center">
                  <h6 class="font-weight-600 mb-0">{{ audio.title_ar}}</h6>
                  <p class="mb-0">{{ audio.desc_ar}} </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <footer class="iq-footer">
      <div class="container-fluid">
         <div class="row">
            <div class="col-sm-12">
               <div class="player row" dir="ltr">
                  <div class="details col-6 col-sm-4 col-md-4 col-lg-4" v-for="(audio,indexo) in audios.slice(index, index + 1)" :key="indexo">
                     <div class="now-playing"></div>
                     <!-- <div class="track-art"></div> -->
                     <img :src="audio.img" class="track-art" alt="">
                     <div>
                        <div class="track-name">{{audio.desc_ar}} </div>
                        <div class="track-artist">{{audio.title_ar}}</div>
                     </div>
                  </div>
                  <div class="slider_container slider_music col-sm-5 col-md-4 col-lg-4">
                     <div class="current-time">{{timer}}</div>
                     <!-- <input type="range" class="seek_slider" @click="seek($event)" ref="progress"> -->
                      <div class="progress" @click="seek($event)" ref="progress">            
                       <div class="progress-bar bg-success " role="progressbar" style="width: 0%;" :style="{'width' : step + '%'}"></div>            
                      </div>
                     <div class="total-duration">{{duration}}</div>
                  </div>
                  <div class="buttons col-6  col-sm-3 col-md-2  col-lg-2">
                     <div class="prev-track" @click="prevButton ? previous() : ''"><i class="fa fa-step-backward fa-2x"></i></div>
                     <div class="playpause-track">
                        <i class="fa fa-play-circle fa-3x" v-if="!pauseTrack" @click="play()"></i>
                        <i class="fa fa-pause-circle fa-3x" v-else @click="pause()"></i>
                      </div>
                     <div class="next-track" @click="nextButton ? next() : ''"><i class="fa fa-step-forward fa-2x"></i></div>
                  </div>
                  <div class="slider_container sound col-sm-6 col-md-2  col-lg-2">
                     <i class="fa fa-volume-down" ></i>
                     <input type="range" class="volume_slider" @click="volume($event)" ref="volBar">
                     <i class="fa fa-volume-up"></i>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { reactive, ref } from '@vue/reactivity';
import { Howl, Howler } from 'howler';
import { watch } from '@vue/runtime-core';
import { useRouter, useRoute } from "vue-router";
export default {
   name: 'AppCatogerys',
    props: {
      id: String,
    },
    mounted(){ 
      var sound = this.audios[this.index].howl;
      var barWidth = (0.9 * 100) / 100;
      this.sliderBtnVol = (this.volBar.offsetWidth * barWidth + this.volBar.offsetWidth * 0.05 - 25);
    },
    async setup(props) {
      const router = useRouter();
      const route = useRoute();
      const audios = ref([]);
      const step =  ref(0);
      const nextButton = ref(true);
      const prevButton = ref(true);
      const random = ref(false);
      const repeat = ref(false);
      const index = ref(0);
      const duration = ref('00:00');
      const timer = ref('00:00');
      const pauseTrack = ref(false);
      const progress = ref(null);
      const volBar = ref(null);
      const sliderBtn = ref(0);
      const sliderBtnVol = ref(null);
      const volumeProgress = ref(90);
      const mutePlayer = ref(false);
      const state = reactive({
        audioPlaying: []
      })
        try {
          await HTTP.get(`CatogeryById.php?LIMIT=100&Cat_id=`+props.id).then((res) => {
            audios.value = res.data.readPost;
          })
        } catch (err) {
          console.log(err);
        }
        function formatTime(secs) {
          var minutes = Math.floor(secs / 60) || 0;
          var seconds = Math.floor(secs - minutes * 60) || 0;
          return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        }
        function play() {
            var sound;
            var audio = audios.value[index.value];
            if (audio.howl) {
                sound = audio.howl
            } else {
                state.audioPlaying[index.value] = false;
                sound = audio.howl =  new Howl({
                src: [audio.source],
                html5: true, // A live stream can only be played through HTML5 Audio.
                format: ['mp3', 'aac'],
                onplay: function(){
                    pauseTrack.value = true; 
                    nextButton.value = true;
                    prevButton.value = true;
                    duration.value = formatTime(sound.duration());
                    requestAnimationFrame(stepFunction.bind(this));
                },
                onpause: function(){
                    pauseTrack.value = false;
                },
                onend: function() {
                    next();
                },
                onseek: function(){
                  window.requestAnimationFrame(stepFunction.bind(this));
                }
              });
            }
            sound.play();
            state.audioPlaying[index.value] = true;
        }
        function pause(indexo) {
            var audio = audios.value[index.value].howl;
            if (audio) {
                audio.pause();
                pauseTrack.value = false; 
                state.audioPlaying[index.value] = false;   
            }
        }
        function stepFunction() {
            var sound = audios.value[index.value].howl;
            var seek = sound.seek();
            timer.value = formatTime(Math.round(seek)); 
            step.value = (seek * 100) / sound.duration();
            sliderBtn.value = (progress.value.offsetWidth * (step.value/100) + progress.value.offsetWidth * 0.05 - 25);
            if (sound.playing()) {
                window.requestAnimationFrame(stepFunction.bind(this));
            }
        }

        function seek(event) {
            var per =  event.offsetX / progress.value.clientWidth; 
            var sound = audios.value[index.value].howl;
            if (sound) {
                if (sound.playing()) {
                    sound.pause();
                    sound.seek(sound.duration() * per);
                    var barWidth = (per * 100) / 100;
                    sliderBtn.value = (progress.value.offsetWidth * barWidth + progress.value.offsetWidth * 0.05 - 25);
                    sound.play();  
                } else {
                    sound.seek(sound.duration() * per);
                    // var barWidth = (per * 100) / 100;
                    sliderBtn.value = (progress.value.offsetWidth * barWidth + progress.value.offsetWidth * 0.05 - 25);
                }                
            }
        }

        function next() {

            nextButton.value = false;
            var audio = audios.value[index.value].howl;
            state.audioPlaying[index.value] = false;
            mutePlayer.value ? mutePlayer.value = false : '';
            audio && audio.mute(true) ?  audio.mute(false) : '';
            
            if ( audio && audios.value.length - 1 ==  index.value) {
                audio.stop();
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length)
                : index.value = 0
            } else {    
                if (audio) {
                    audio.stop();
                }
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length) 
                :  
                index.value ++;                 
            }
            play();
        } 

        function previous() {

            var audio = audios.value[index.value].howl;
            prevButton.value = false;
            state.audioPlaying[index.value] = false;

            mutePlayer.value ? mutePlayer.value = false : '';
            audio && audio.mute(true) ?  audio.mute(false) : '';

            if (!audio) {
                index.value = audios.value.length - 1;
            } else if (audio && index.value == 0) {
                audio.stop();
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length)
                : index.value = audios.value.length - 1;              
            } else if(audio) {    
                            
                audio.stop();
                // repeat.value ? index.value = index.value
                // :
                random.value ? index.value = Math.floor(Math.random() * audios.value.length)
                : index.value --;
            } 
            play();
        }

        function selectSound(indexSelected) {

            var audio = audios.value[index.value].howl;
            if (audio) {
                audio.stop();
                state.audioPlaying[index.value] = false;
            }
            index.value = indexSelected;
            play();
        }

        function volume(event) {
    
            var per = event.layerX / parseFloat(volBar.value.scrollWidth);
            var barWidth = (per * 100) / 100;
            volumeProgress.value = barWidth * 100;
            sliderBtnVol.value = (volBar.value.offsetWidth * barWidth + volBar.value.offsetWidth * 0.05 - 25);
            Howler.volume(per);
        }

        function mute() {
            
            var audio = audios.value[index.value].howl;
            if (audio) {
               mutePlayer.value  = !mutePlayer.value;
               mutePlayer.value ? audio.mute(true) : audio.mute(false)
            }
        }

        watch(() => route,(to, from) => {
          pause();
        // console.log(`${previous} and ${current}`);
        // window.scrollTo(0, 0);
      },
      { deep: true }
    );

        return {
          play,pause,duration,formatTime,audios,pauseTrack,next,previous,
          index,timer,step,stepFunction,seek,selectSound,state,random,repeat,
          progress,volume,volBar,volumeProgress,sliderBtn,mute,mutePlayer,
          sliderBtnVol,nextButton,prevButton
        }
    },
}
</script>

<style scoped>
.feature-list {
 padding: 15px 0px;
z-index: 1;
margin-top: -62px;
position: absolute;
background: #826F46BD;
width: 83%;
border-bottom-right-radius: 16px;
line-height: .5;
}
   .progress{
  min-width: 250px;
  height: 8px;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  /* background: rgba(0,0,0,0.06) */
}
 .progress-bar{
  opacity: 0.8;
}
</style>